<template>
    <Layout :auth='auth'>
        <Head :title="project ? project.name : 'Create Project'" />
        <template v-slot:title>
            <template v-if="project">{{ project.name }}</template>
            <template v-else>Create Project</template>
        </template>
        <template v-slot:actions>
            <btn v-if="project" white :href="'/projects/'+project.id" outline class="mr-2">
                Back To Project
            </btn>
            <btn v-if="project" white @click="save" :loading="saving">
                Save Project
            </btn>
            <btn v-else white @click="save" :loading="saving">
                Create Project
            </btn>
        </template>

        <form action="#" method="POST" @submit.prevent="save" class="space-y-5">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Project Information
                    </h3>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label
                            for="name"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <inp
                            type="text"
                            name="name"
                            v-model="name"
                        ></inp>
                        <error v-if="errors.name">{{
                            errors.name[0]
                        }}</error>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="launch_date"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Launch Date
                            </label>
                            <inp
                                type="date"
                                name="launch_date"
                                v-model="launch_date"
                            />
                            <error v-if="errors.launch_date">{{
                                errors.launch_date[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="status"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Status
                            </label>
                            <hgselect
                                name="status"
                                v-model="status"
                                :options="projectStatusOptions"
                            ></hgselect>
                            <error v-if="errors.status">{{
                                errors.status[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="domains"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Domains
                            </label>
                            <hgselect
                                name="domains"
                                v-model="domains"
                                :create-new="addClientDomain"
                                :options="availableDomains"
                                multiple
                                placeholder="Add some domains..."
                            ></hgselect>
                            <error v-if="errors.domains">{{
                                errors.domains[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="trello_board"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Trello Board
                            </label>
                            <hgselect
                                v-model="trello_board"
                                :options="trelloBoardOptions"
                                placeholder="Select Board"
                                @open="loadTrelloBoards"
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="launch_date"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Google Analytics Property ID
                            </label>
                            <div v-if="ga4_property_id && !ga4.account_id && !ga4.changing">
                                <inp
                                    type="text"
                                    name="ga4_property_id"
                                    v-model="ga4_property_id"
                                />
                                <a
                                    @click.stop.prevent="ga4.changing = true"
                                    href="#"
                                    class="text-blue-600 hover:text-blue-500 text-sm mt-1"
                                >Change Property Id</a>
                            </div>
                            <div class="flex gap-2" v-else>
                                <hgselect
                                    placeholder="Select a GA4 Account"
                                    name="ga4_account_id"
                                    v-model="ga4.account_id"
                                    :options="ga4.accounts"
                                    :loading="ga4.loading"
                                    @open="getGA4Accounts($event)"
                                ></hgselect>
                                <hgselect
                                    :disabled="!ga4.account_id"
                                    placeholder="Select a GA4 Property"
                                    name="ga4_property_id"
                                    v-model="ga4_property_id"
                                    :options="ga4.properties"
                                    :loading="ga4.loading"
                                    @open="getGA4Properties($event)"
                                ></hgselect>
                            </div>

                            <error v-if="errors.ga4_property_id">{{
                                errors.ga4_property_id[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="google_ads_customer_ids"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Google Ads Customer Id
                            </label>
                            <div class="flex gap-2">
                                <hgselect
                                    placeholder="Select a Google Ads Customer"
                                    name="google_ads_customer_ids"
                                    v-model="google_ads_customer_ids"
                                    :multiple="true"
                                    :options="gads.customers"
                                    :loading="gads.loading"
                                    @open="getGAdsCustomers($event)"
                                ></hgselect>
                            </div>
                            <error v-if="errors.google_ads_customer_ids">{{
                                errors.google_ads_customer_ids[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="launch_date"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                SEM Rush Project ID
                            </label>
                            <div v-if="sem_rush_project_id && !semRush.changing">
                                <inp
                                    type="text"
                                    name="sem_rush_project_id"
                                    v-model="sem_rush_project_id"
                                />
                                <a
                                    @click.stop.prevent="semRush.changing = true"
                                    href="#"
                                    class="text-blue-600 hover:text-blue-500 text-sm mt-1"
                                >Change Project Id</a>
                            </div>
                            <div class="flex gap-2" v-else>
                                <hgselect
                                    placeholder="Select a SEM Rush Project"
                                    name="sem_rush_project_id"
                                    v-model="sem_rush_project_id"
                                    :options="semRush.projects"
                                    :loading="semRush.loading"
                                    @open="getSEMRushProjects($event)"
                                ></hgselect>
                            </div>
                            <error v-if="errors.sem_rush_project_id">{{
                                errors.sem_rush_project_id[0]
                            }}</error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Services
                    </h3>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-3 gap-4">
                        <template v-for="service in groupedServices" :key="service.name">
                            <div>{{ service.name }}</div>
                            <hgselect
                                clearable
                                placeholder="None"
                                :options="service.levels"
                                v-model="selectedServices[service.name].level"
                            />
                            <hgselect
                                clearable
                                :disabled="!selectedServices[service.name].level"
                                placeholder="Status"
                                :options="serviceStatusOptions"
                                v-model="selectedServices[service.name].status"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </form>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import error from "../../Common/error.vue"
import inp from "../../Common/inp.vue"
import btn from "../../Common/btn.vue"
import money from "../../Common/money.vue"
import toggle from "../../Common/toggle.vue"
import { find } from "lodash"
import hgselect from '../../Common/hgselect.vue'
import { Head, usePage } from '@inertiajs/vue3'
import axios from "axios"

export default {
    components: { btn, money, toggle, inp, Head, Layout, error, hgselect },
    props: {
        auth: Object,
        client: Object,
        project: {
            type: Object,
            default: null
        },
        allServices: Array
    },
    data() {
        const launch_date = (this.project && this.project.launch_date)
                            ? this.project.launch_date.split("T")[0]
                            : null

        const selectedServices = {};
        (this.project && this.project.services || []).forEach((service) => {
            selectedServices[service.name] = {
                level: service.level,
                status: service.pivot.status
            };
        })

        return {
            saving: false,
            id: this.project ? this.project.id : null,
            name: this.project ? this.project.name : "",
            status: this.project ? this.project.status : null,
            launch_date,
            ga4_property_id: this.project ? this.project.ga4_property_id : null,
            google_ads_customer_ids: this.project ? this.project.google_ads_customer_ids : null,
            sem_rush_project_id: this.project ? this.project.sem_rush_project_id : null,
            sem_rush_campaigns_ids: this.project ? this.project.sem_rush_campaigns_ids : null,
            domains: (this.project ? this.project.domains : []) || [],
            availableDomains: (this.project ? this.project.domains : []) || [],
            trello_board: this.project && this.project.trello_board ? this.project.trello_board.id : null,
            ga4: {
                changing: false,
                loading: false,
                accounts: null,
                properties: null,
                account_id: null,
            },
            gads: {
                changing: false,
                loading: false,
                customers: this.project ? this.project.google_ads_customer_ids : null
            },
            semRush: {
                changing: false,
                loading: false,
                projects: null
            },
            selectedServices,
            trelloBoards: this.project && this.project.trello_board ? [{
                id: this.project.trello_board.id,
                name: this.project.trello_board.name
            }]: null
        }
    },
    computed: {
        trelloBoardOptions() {
            return (this.trelloBoards || []).reduce((acc, board) => {
                acc[board.id] = board.name
                return acc
            }, {})
        },
        projectStatusOptions() {
            return {
                active: 'Active',
                canceled: 'Canceled',
                delinquent: 'Delinquent',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            };
        },
        serviceStatusOptions() {
            return {
                new: 'New',
                onboarding: 'Onboarding',
                active: 'Active',
                canceled: 'Canceled',
                delinquent: 'Delinquent',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            };
        },
        errors() {
            return usePage().props.errors;
        },
        groupedServices() {
            const groups = {}
            this.allServices.forEach((service) => {
                if (!(service.name in groups)) {
                    groups[service.name] = {
                        name: service.name,
                        levels: []
                    }
                }
                groups[service.name].levels.push(service.level);
                groups[service.name].levels.sort();

                if (!(service.name in this.selectedServices)) {
                    this.selectedServices[service.name] = {
                        level: null,
                        status: null
                    }
                }
            })
            return groups
        }
    },
    methods: {
        async loadTrelloBoards() {
            const { data } = await axios.get(this.$route('trello.boards'))
            this.trelloBoards = data
        },
        async getSEMRushProjects() {
            this.semRush.loading = true
            const { data } = await axios.get(`/semrush/projects`)
            this.semRush.loading = false
            const projects = {}
            Object.entries(data).forEach(([id, name]) => {
                projects[id] = `${name} (${id})`
            })
            this.semRush.projects = projects
        },
        async getGAdsCustomers() {
            this.gads.loading = true
            const { data } = await axios.get(`/gads/customers`)
            this.gads.loading = false
            const customers = {}
            Object.entries(data).forEach(([id, name]) => {
                customers[id] = `${name} (${id})`
            })
            this.gads.customers = customers
        },
        async getGA4Properties() {
            this.ga4.loading = true
            const { data } = await axios.get(`/ga4/properties/${this.ga4.account_id}`)
            this.ga4.loading = false
            const properties = {}
            Object.entries(data).forEach(([id, name]) => {
                properties[id] = `${name} (${id})`
            })
            this.ga4.properties = properties
        },
        async getGA4Accounts() {
            this.ga4.loading = true
            const { data } = await axios.get('/ga4/accounts')
            this.ga4.loading = false
            const accounts = {}
            Object.entries(data).forEach(([id, name]) => {
                accounts[id] = `${name} (${id})`
            })
            this.ga4.accounts = accounts
        },
        addClientDomain(domain) {
            if (this.availableDomains.indexOf(domain) == -1) {
                this.availableDomains.push(domain)
            }
            if (this.domains.indexOf(domain) == -1) {
                this.domains.push(domain)
            }
        },
        async save() {
            this.saving = true
            const data = new FormData()

            data.append("name", this.name || "")
            data.append("launch_date", this.launch_date || "")
            data.append("status", this.status || "")
            data.append("ga4_property_id", this.ga4_property_id || "");
            (this.google_ads_customer_ids || []).forEach((id) => {
                data.append("google_ads_customer_ids[]", id)
            })
            if ((this.google_ads_customer_ids || []).length == 0) {
                data.append("google_ads_customer_ids", "")
            }
            data.append("sem_rush_project_id", this.sem_rush_project_id || "")

            if (this.trello_board) {
                const board = this.trelloBoards.find((board) => board.id == this.trello_board)
                data.append("trello_board[id]", this.trello_board)
                data.append("trello_board[name]", board.name)
                data.append("trello_board[url]", board.url)
            }

            this.domains.forEach((domain) => {
                data.append(`domains[]`, domain)
            })

            Object.keys(this.selectedServices).forEach((serviceName) => {
                const serviceLevel = this.selectedServices[serviceName]
                const service = find(this.allServices, {
                    name: serviceName,
                    level: serviceLevel.level
                })

                if (service) {
                    data.append(`services[${service.id}]`, serviceLevel.status)
                }
            })

            try {
                if (this.id) {
                    data.append("_method", "PUT")
                    await axios.post(
                        this.$route("projects.update", this.id),
                        data
                    )
                } else {
                    data.append("client_id", this.client.id)
                    const response = await axios.post(this.$route("projects.store"), data)
                    if (response.data) {
                        this.$inertia.visit(this.$route("projects.show", response.data.id))
                    }
                }
            } catch (err) {
                console.error(err)
            }
            this.saving = false
        }
    }
}
</script>

<style lang="postcss" scoped></style>
