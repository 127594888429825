<template>
    <Layout :auth='auth'>
        <Head title="Automations" />
        <template v-slot:title>
            Automation
        </template>
        <template v-slot:actions>
            <btn
                white
                class="ml-4 whitespace-nowrap"
                @click="createAutomation()"
            >Create Automation</btn>
        </template>
        <div>
            <div class="bg-white shadow overflow-hidden sm:rounded-md">
                <ul class="divide-y divide-gray-200" v-if="automations.length">
                    <li v-for="automation in automations" :key="automation.id">
                        <a href="#" @click.stop.prevent="activate(automation)" class="block hover:bg-gray-50">
                            <div class="px-4 py-4 sm:px-6 flex items-center">
                                <avatar :user="automation.user" size="8" class="mr-4" />
                                <div class="w-full">
                                    <div class="flex items-center justify-between">
                                        <p class="text-sm font-medium text-blue-600 truncate flex items-center">
                                            {{ automation.name }}
                                        </p>
                                        <div class="ml-2 flex-shrink-0 flex">
                                            <p
                                                v-if="automation.is_active"
                                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Active
                                            </p>
                                            <p
                                                v-else
                                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                Inactive
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-2 sm:flex sm:justify-between">
                                        <div class="sm:flex">
                                            <p class="flex items-center text-sm text-gray-500">
                                                {{ events[automation.event] }}
                                            </p>
                                        </div>
                                        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                            <i class="fas fa-terminal mr-2 relative top-px"></i>
                                            <p>
                                                Executed
                                                <span class='font-bold text-blue-800'>{{ automation.executed }}</span> time{{ automation.executed == 1 ? '' : 's' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <div class="rounded-md bg-blue-50 p-4" v-else>
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <i class="fal fa-robot text-blue-400"></i>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm text-blue-700">
                                You have not created any automations yet!
                            </p>
                            <p class="mt-3 text-sm md:mt-0 md:ml-6">
                                <a href="#" @click.prevent="createAutomation()" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                    Create Your First Automation <span aria-hidden="true">&rarr;</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <modal :show='activeAutomation != null' @close='activeAutomation = null' size='3xl' overflowVisible @keypress.stop :closeOnBackgroundClick='false'>
            <template v-if="activeAutomation">
                <div class="flex items-center mb-4">
                    <button
                        @click="activeAutomation.is_active = !activeAutomation.is_active"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="automationIsActive"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        :class="{
                            'bg-gray-200': !activeAutomation.is_active,
                            'bg-blue-600': activeAutomation.is_active,
                        }"
                    >
                        <span
                            aria-hidden="true"
                            class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            :class="{
                                'translate-x-0': !activeAutomation.is_active,
                                'translate-x-5': activeAutomation.is_active,
                            }"
                        ></span>
                    </button>
                    <span class="ml-3" id="automationIsActive">
                        <span class="text-sm font-medium text-gray-900">Automation is active</span>
                    </span>
                </div>
                <div class="mb-4">
                    <label for="name" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Automation Name</label>
                    <inp v-model='activeAutomation.name' />
                </div>
                <div class="flex mb-4 space-x-4">
                    <div class="w-full">
                        <label for="event" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Event Trigger</label>
                        <hgselect
                            v-model='activeAutomation.event'
                            :options="events"
                            :disabled='!!activeAutomation.id'
                        ></hgselect>
                    </div>
                    <div
                        v-if="activeAutomation.event == 'schedule.monthly' || activeAutomation.event == 'schedule.weekly' || activeAutomation.event == 'schedule.daily'"
                        class="w-full"
                    >
                        <label for="scope" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Event Scope</label>
                        <hgselect
                            v-model='activeAutomation.scope'
                            :options="scopes"
                        ></hgselect>
                    </div>
                </div>
                <div v-if="activeAutomation.event == 'schedule.monthly'" class="mb-4">
                    <div class="grid grid-cols-6 space-x-4">
                        <div class="col-span-4">
                            <label for="" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Day(s) of the Month</label>
                            <ul class="flex space-x-4">
                                <li
                                    v-for='(day, idx) in activeAutomation.event_data.days'
                                    :key='"mday-" + day'
                                >
                                    {{ day }}
                                    <i @click.prevent='removeDayOfMonth(idx)' class='fal fa-times text-gray-300 hover:text-red-600 cursor-pointer ml-1'></i>
                                </li>
                            </ul>
                            <div class="mt-2 flex space-x-4">
                                <hgselect
                                    v-model='dayOfMonthToAdd'
                                    :options="daysOfMonth"
                                ></hgselect>
                                <btn @click='addDayOfMonth'>Add</btn>
                            </div>
                        </div>
                        <div class="">
                            <label for="hour" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Hour</label>
                            <hgselect
                                v-model='activeAutomation.event_data.hour'
                                :options="hours"
                            ></hgselect>
                        </div>
                        <div class="">
                            <label for="minute" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Minute</label>
                            <hgselect
                                v-model='activeAutomation.event_data.minute'
                                :options="minutes"
                            ></hgselect>
                        </div>
                    </div>
                </div>
                <div v-if="activeAutomation.event == 'schedule.weekly'" class="mb-4">
                    <div class="grid grid-cols-6 space-x-4">
                        <div class="col-span-4">
                            <label for="" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Day(s) of the Week</label>
                            <div class="mt-2 grid grid-cols-7 gap-4">
                                <div class="flex items-center cursor-pointer" v-for="(dow, idx) in dows" :key="'dow-' + dow">
                                    <input
                                    :id="'dow-' + dow"
                                    type="checkbox"
                                    :value="idx"
                                    v-model="activeAutomation.event_data.days"
                                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                                    />
                                    <label :for="'dow-' + dow" class="ml-3">
                                        <span class="block text-sm font-medium text-gray-700">{{ dow }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <label for="hour" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Hour</label>
                            <hgselect
                                v-model='activeAutomation.event_data.hour'
                                :options="hours"
                            ></hgselect>
                        </div>
                        <div class="">
                            <label for="minute" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Minutes</label>
                            <hgselect
                                v-model='activeAutomation.event_data.minute'
                                :options="minutes"
                            ></hgselect>
                        </div>
                    </div>
                </div>
                <div v-if="activeAutomation.event == 'schedule.daily'" class="mb-4">
                    <div class="grid grid-cols-6 space-x-4">
                        <div class="">
                            <label for="hour" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Hour</label>
                            <hgselect
                                v-model='activeAutomation.event_data.hour'
                                :options="hours"
                            ></hgselect>
                        </div>
                        <div class="">
                            <label for="minute" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Minutes</label>
                            <hgselect
                                v-model='activeAutomation.event_data.minute'
                                :options="minutes"
                            ></hgselect>
                        </div>
                    </div>
                </div>
                <div class="mb-4" :key="'automation-qb-' + activeAutomation.id">
                    <vue-query-builder
                        ref="queryBuilder"
                        :rules="rules"
                        :key="'query-' + activeAutomation.event"
                        :styled="false"
                    ></vue-query-builder>
                </div>
                <div class="mb-4">
                    <label for="newAction" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Event Action</label>
                    <div class="flex">
                        <div class="flex-grow">
                            <hgselect
                                v-model='newAction'
                                :options="options.actions"
                            ></hgselect>
                        </div>
                        <btn
                            class="ml-2"
                            blue
                            :disabled="!newAction"
                            @click='addAction'
                        >Add Action</btn>
                    </div>
                </div>
                <div v-if="activeAutomation.actions.length">
                    <nav class="flex mb-4" aria-label="Breadcrumb">
                        <ol class="flex flex-wrap items-center space-x-4">
                            <li
                                v-for="(action, idx) in activeAutomation.actions"
                                :key="'action-bc-' + idx"
                            >
                                <div class="flex items-center">
                                    <i class="fal fa-angle-right text-gray-400" v-if="idx > 0"></i>
                                    <a
                                        href="#"
                                        @click.stop.prevent="activeActionIndex = idx"
                                        :class="{
                                            'text-gray-500 hover:text-gray-700': activeActionIndex != idx,
                                            'text-blue-500 hover:text-blue-700': activeActionIndex == idx,
                                            'ml-4': idx > 0
                                        }"
                                    >{{ action.type in options.actions ? options.actions[action.type] : action.type }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="px-6 py-4 bg-gray-50 border border-gray-100 rounded">
                        <div v-if="activeAction.type == 'sms'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="number" class="block text-sm font-medium leading-5 text-gray-700">Send SMS To</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <hgselect
                                            v-model="activeAction.user_id"
                                            :options="users"
                                            key="sms-user-id"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">Message</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.message"
                                            key="sms-message"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'email'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">Recipient</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.address"
                                            key="mail-address"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">Subject Line</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.subject"
                                            key="mail-subject"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="flex justify-between text-sm font-medium leading-5 text-gray-700">
                                        <span>
                                            Message
                                            <a
                                                href="https://www.markdownguide.org/cheat-sheet/"
                                                target="_blank"
                                                class="ml-1 text-xs text-blue-500 hover:underline"
                                            >Supports Markdown</a>
                                        </span>
                                        <template v-if="showMailPreview">
                                            <a class="text-xs text-blue-500 hover:underline" href="#" @click.prevent="showMailPreview = false">Hide Preview</a>
                                        </template>
                                        <template v-else>
                                            <a class="text-xs text-blue-500 hover:underline" href="#" @click.prevent="showMailPreview = true">Show Preview</a>
                                        </template>
                                    </label>
                                    <div v-if='showMailPreview'>
                                        <div class="border bg-white p-6 rounded">
                                            <div class="prose max-w-full" v-html="mailPreview"></div>
                                        </div>
                                    </div>
                                    <div v-else class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.message"
                                            key="mail-message"
                                            multi-line
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'slack'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">Slack Channel</label>
                                    <span class="block text-xs font-medium leading-5 text-gray-400">Use <code>#channel</code> for a channel, and your <a target="_blank" href="https://zapier.com/help/doc/common-problems-slack" class="font-mono underline">user id</a> for a direct message.</span>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp v-model="activeAction.channel" key='slack-channel' :variables="variables" />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="link" class="block text-sm font-medium leading-5 text-gray-700">Link To</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.link"
                                            key='slack-link'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">Subject</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.title"
                                            key='slack-title'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="channel" class="block text-sm font-medium leading-5 text-gray-700">
                                        Message
                                        <a
                                            href="https://api.slack.com/reference/surfaces/formatting"
                                            target="_blank"
                                            class="ml-1 text-xs text-blue-500 hover:underline"
                                        >Supports Markdown</a>
                                    </label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.message"
                                            multi-line
                                            key='slack-message'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'trello'">
                            <div class="grid grid-cols-2 gap-6 mb-4">
                                <div>
                                    <label for="board" class="block text-sm font-medium leading-5 text-gray-700">Board</label>
                                    <hgselect
                                        v-model="activeAction.board"
                                        :options="options.trelloBoards"
                                        placeholder="Select Board"
                                        @open="loadTrelloBoards"
                                    />
                                </div>
                                <div>
                                    <label for="list" class="block text-sm font-medium leading-5 text-gray-700">List</label>
                                    <hgselect
                                        v-model="activeAction.list"
                                        :options="options.trelloLists"
                                        placeholder="Select List"
                                    />
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="labels" class="block text-sm font-medium leading-5 text-gray-700">Labels</label>
                                    <hgselect
                                        v-model="activeAction.labels"
                                        :options="options.trelloLabels"
                                        placeholder="Select Labels"
                                        multiple
                                    />
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Card Title</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.name"
                                            key='trello-name'
                                            multi-line
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Card Body</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.description"
                                            key='trello-description'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'action'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="user_id" class="block text-sm font-medium leading-5 text-gray-700">Assign To</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <hgselect
                                            v-model="activeAction.user_id"
                                            :options="users"
                                            key="action-user"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="notification" class="block text-sm font-medium leading-5 text-gray-700">Notification</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.notification"
                                            key="action-notification"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="activeAction" class="block text-sm font-medium leading-5 text-gray-700">Call To Action</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.action"
                                            key="action-action"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'memo.status'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="status" class="block text-sm font-medium leading-5 text-gray-700">Feedback Status</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <hgselect
                                            v-model="activeAction.status"
                                            :options="options.feedback.statuses"
                                            key="action-status"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'memo.assigned_to'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="assigned_to" class="block text-sm font-medium leading-5 text-gray-700">Team Member</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <hgselect
                                            v-model="activeAction.assigned_to"
                                            :options="options.users"
                                            key="action-assigned-team"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'memo.assigned_team'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="assigned_team" class="block text-sm font-medium leading-5 text-gray-700">Team</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <hgselect
                                            v-model="activeAction.assigned_team"
                                            :options="options.teams"
                                            key="action-assigned-team"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'memo.comment'">
                            <div class="grid grid-cols-1 gap-6 mb-4">
                                <div>
                                    <label for="comment" class="block text-sm font-medium leading-5 text-gray-700">
                                        Message
                                    </label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <inp
                                            :variables="variables"
                                            v-model="activeAction.comment"
                                            multi-line
                                            key='memo-comment'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeAction.type == 'memo.watch'" class="mt-2">
                        </div>
                        <div v-if="activeAction" class="-mt-4">
                            <a @click.stop.prevent="removeActiveAction()" href="#" class="text-xs text-blue-600">Remove Action</a>
                        </div>
                    </div>
                </div>
                <div class="pt-4 flex justify-center">
                    <div>
                        <btn
                            red outline
                            @click='deactivate()'
                        >Cancel</btn>
                        <btn
                            class="ml-2"
                            :disabled="deleting"
                            red
                            @click='remove()'
                        >Delete Automation</btn>
                    </div>
                    <div class="flex-grow text-right">
                        <btn
                            class="mr-2"
                            :disabled="testing"
                            green
                            @click='testActiveAction()'
                        >Test Automation</btn>
                        <btn
                            :loading="saving"
                            blue
                            :disabled='!canSave'
                            @click='save'
                        >{{ activeAutomation.id ? 'Save Changes' : 'Create Automation' }}</btn>
                    </div>
                </div>
            </template>
        </modal>
        <modal :show='testing' @close='testing = false' size='xl' overflowVisible @keypress.stop :closeOnBackgroundClick='true'>
            <div v-if="testAgainst.loading">Loading <i class="fal fa-spinner-third fa-spin"></i></div>
            <div v-else>
                <div class="text-sm text-gray-500">Pick a {{ testAgainst.label }} to test against:</div>
                <div class="block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex">
                        <a
                            href="#"
                            class="whitespace-nowrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5"
                            :class="{
                                'text-gray-500 hover:text-gray-700 hover:border-gray-300': testAgainst.showing == 'objects',
                                'text-blue-500 hover:text-blue-700 hover:border-blue-300': testAgainst.showing == 'matches'
                            }"
                            @click.prevent="testAgainst.showing = 'matches'"
                        >
                            {{ testAgainst.label }} Matches ({{ (testAgainst.objects.matches || []).length }})
                        </a>
                        <a
                            href="#"
                            class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5"
                            :class="{
                                'text-gray-500 hover:text-gray-700 hover:border-gray-300': testAgainst.showing == 'matches',
                                'text-blue-500 hover:text-blue-700 hover:border-blue-300': testAgainst.showing == 'objects'
                            }"
                            @click.prevent="testAgainst.showing = 'objects'"
                        >
                            Recent {{ testAgainst.label }}s ({{ (testAgainst.objects.objects || []).length }})
                        </a>
                        </nav>
                    </div>
                </div>
                <div class="mt-4 space-y-4 max-h-56 overflow-auto">
                    <div class="flex items-center" v-for="(obj, idx) in testAgainst.objects[testAgainst.showing]" :key="obj.id + '-' + idx">
                        <input v-model="testAgainst.object" :value="obj.id" :id="obj.id" type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300">
                        <label :for="obj.id" class="ml-3 block text-sm text-gray-700">
                            <template v-if="testAgainst.label == 'Action'">
                                <div class="flex items-center pointer-events-none">
                                    <avatar v-if="obj.user" :user="obj.user" class="mr-2" size="8" />
                                    <div>
                                        <div v-html="obj.notification" />
                                        <div v-html="obj.action" class="text-gray-400" />
                                    </div>
                                </div>
                            </template>
                            <template v-if="testAgainst.label == 'Project'">
                                <div class="flex items-center pointer-events-none">
                                    <div>
                                        <div>{{ obj.name }}</div>
                                        <div class="text-gray-400">{{ (obj.domains || []).join(', ') }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="testAgainst.label == 'Feedback'">
                                <div class="flex items-center pointer-events-none">
                                    <avatar v-if="obj.user" :user="obj.user" class="mr-2" size="8" />
                                    <div>
                                        <div v-html="obj.subject" />
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="testAgainst.label == 'Time Entry'">
                                <div class="flex items-center pointer-events-none whitespace-nowrap gap-2">
                                    <avatar v-if="obj.user" :user="obj.user" class="mr-2" size="8" />
                                    <div class="flex flex-col">
                                        <div class="flex gap-2">
                                            <div>{{ obj.week }}</div>
                                            <div class="text-gray-400">{{ clientName(obj.client_id) }}</div>
                                        </div>
                                        <div class="text-sm truncate w-[400px]">{{ obj.description }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="testAgainst.label == 'Sprint'">
                                <div class="flex items-center pointer-events-none">
                                    <div>
                                        <div>{{ clientName(obj.client_id) }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="testAgainst.label == 'Payment'">
                                <div class="flex items-center pointer-events-none">
                                    <div>
                                        <span class="font-medium">{{ clientName(obj.client_id) }}</span>
                                        <span class="text-green-600 ml-1">{{ money(obj.amount) }}</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="testAgainst.label == 'Service'">
                                <div class="flex items-center pointer-events-none">
                                    <div>
                                        <span class="font-medium">{{ obj.project.name }} - {{ obj.service.name }}</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="testAgainst.label == 'Note'">
                                <div class="flex items-center pointer-events-none">
                                    <div>
                                        <div>{{ clientName(obj.client_id) }}</div>
                                        <div class="text-gray-400 whitespace-pre-wrap truncate" v-html="obj.note" />
                                    </div>
                                </div>
                            </template>
                        </label>
                    </div>
                </div>
                <div class="pt-4 flex justify-center">
                    <div>
                        <btn
                            red outline
                            @click='testing = false'
                        >Cancel</btn>
                    </div>
                    <div class="flex-grow text-right">
                        <btn
                            :loading="testAgainst.running"
                            blue
                            :disabled='testAgainst.object == null'
                            @click='runTest'
                        >Test Automation</btn>
                    </div>
                </div>
            </div>
        </modal>
    </Layout>
</template>


<script>
import VueQueryBuilder from "vue-query-builder"
import inp from "../../Common/inp.vue"
import btn from "../../Common/btn.vue"
import avatar from "../../Common/avatar.vue"
import modal from "../../Common/modal.vue"
import Layout from "../../Common/Layout.vue"
import auth from "../../Common/Mixins/auth"
import hgselect from "../../Common/hgselect.vue"
import { get, cloneDeep } from "lodash"
import axios from "axios"
import Avatar from '../../Common/avatar.vue'
import { nextTick } from "vue"
import { Head } from '@inertiajs/vue3'
import { markdown } from 'markdown'

export default {
    name: 'AutomationsIndex',
    mixins: [auth],
    components: {
        inp,
        btn,
        Layout,
        Head,
        hgselect,
        modal,
        avatar,
        Avatar,
        VueQueryBuilder
    },
    props: {
        auth: Object,
        automations: Array,
        users: Object,
        clients: Object,
        teams: Object,
        services: Object,
    },
    data() {
        return {
            saving: false,
            deleting: false,
            testing: false,
            testAgainst: {
                running: false,
                loading: false,
                label: '',
                showing: 'matches',
                object: null,
                objects: []
            },
            activeAutomation: null,
            activeActionIndex: 0,
            dayOfMonthToAdd: '',
            newAction: '',
            daysOfMonth: [
                1,2,3,4,5,6,7,8,9,10,
                11,12,13,14,15,16,17,
                18,19,20,21,22,23,24,
                25,26,27,28,29,30,31,
                'First Weekday', 'First Monday',
                'Last Day', 'Last Weekday'
            ],
            dows: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
            hours: ['0',1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,21,22,23],
            minutes: ['00','15','30','45'],
            newAutomation: {
                id: null,
                is_active: true,
                event: '',
                event_data: null,
                scope: null,
                name: '',
                query: {
                    "logicalOperator": "all",
                    "children": []
                },
                actions: []
            },
            trello: {
                boards: [],
                lists: [],
                labels: []
            },
            events: {
                'schedule.monthly': 'Monthly',
                'schedule.weekly': 'Weekly',
                'schedule.daily': 'Daily',
                'action.created': 'Action Created/Updated',
                'memo.saved': 'Feedback Created/Updated',
                'note.created': 'Note Created',
                'payment.received': 'Payment Received',
                'payment.paid': 'Payment Paid',
                'project.created': 'Project Created',
                'project.launched': 'Project Launched',
                'entry.saved': 'Time Entry Created/Updated',
                'sprint.created': 'Work Order Created',
                'sprint.updated': 'Work Order Updated',
                'service.started': 'Service Started',
                'service.changed': 'Service Level Changed',
            },
            showMailPreview: false,
        }
    },
    computed: {
        mailPreview() {
            return markdown.toHTML(this.activeAction.message)
        },
        options() {
            const actions = {
                'slack': 'Send Slack Notification',
                'trello': 'Create Trello Card',
                'action': 'Create Table Action',
                'sms': 'Send SMS',
                'email': 'Send Email',
            }

            if (this.activeAutomation.event == 'memo.saved') {
                actions['memo.status'] = 'Update Feedback Status';
                actions['memo.assigned_to'] = 'Assign Feedback to User';
                actions['memo.assigned_team'] = 'Assign Feedback to Team';
                actions['memo.comment'] = 'Add Comment To Feedback';
                actions['memo.watch'] = 'Watch Feedback';
            }

            return {
                actions,
                users: this.users,
                teams: this.teams,
                trelloBoards: this.trello.boards.reduce((acc, board) => {
                    acc[board.id] = board.name
                    return acc
                }, {}),
                trelloLists: this.trello.lists.reduce((acc, list) => {
                    acc[list.id] = list.name
                    return acc
                }, {}),
                trelloLabels: this.trello.labels.reduce((acc, label) => {
                    acc[label.id] = label.name ? `${label.name} (${label.color})` : label.color
                    return acc
                }, {}),
                feedback: {
                    statuses: {
                        'new': 'New',
                        'in-progress': 'In Progress',
                        'need-more-information': 'Need More Information',
                        'waiting-on-client': 'Waiting on Client',
                        'resolved': 'Resolved',
                        'reopened': 'Reopened',
                    }
                }
            };
        },
        canSave() {
            if (this.activeAutomation.name) {
                return true
            }
            return false
        },
        activeAction() {
            return get(this.activeAutomation, `actions.${this.activeActionIndex}`, null)
        },
        scopes() {
            return {
                'payments': 'Payments',
                'sprints': 'Work Orders',
            }
        },
        variables() {
            if (this.activeAutomation.event == 'action.created') {
                return [
                    { value: 'userName', label: 'User Name' },
                    { value: 'userSlackId', label: 'User Slack Id' },
                    { value: 'actionNotification', label: 'Action Title' },
                    { value: 'actionAction', label: 'Action Call To Action' },
                    { value: 'actionCompletedAt', label: 'Action Is Completed?' },
                    { value: 'actionUrl', label: 'Action URL' },
                ]
            } else if (this.activeAutomation.event == 'memo.saved') {
                return [
                    { value: 'clientName', label: 'Client Name' },
                    { value: 'clientUrl', label: 'Client URL' },
                    { value: "userName", label: "User Name" },
                    { value: "userEmail", label: "User Email" },
                    { value: 'memoType', label: 'Memo Type' },
                    { value: 'memoUrl', label: 'Memo Url' },
                    { value: 'memoSubject', label: 'Memo Subject' },
                    { value: 'memoDescription', label: 'Memo Description' },
                    { value: 'memoStatus', label: 'Memo Status' },
                    { value: 'memoAssignedTeam', label: 'Memo Assigned Team' },
                    { value: 'memoAssignedToName', label: 'Memo Assigned User Name' },
                    { value: 'memoAssignedToEmail', label: 'Memo Assigned User Email' },
                    { value: 'memoAssignedToSlackId', label: 'Memo Assigned User Slack ID' },
                ]
            } else if (this.activeAutomation.event == 'entry.saved') {
                return [
                    { value: 'userName', label: 'User Name' },
                    { value: 'clientName', label: 'Client Name' },
                    { value: 'clientUrl', label: 'Client URL' },
                    { value: 'entryWeek', label: 'Week' },
                    { value: 'entryPlanned', label: 'Planned Hours' },
                    { value: 'entryActual', label: 'Actual Hours' },
                    { value: 'entryBillableNow', label: 'Billable Now' },
                    { value: 'entryBillableGoal', label: 'Billable Goal' },
                    { value: 'entryRequestSprint', label: 'Request Sprint?' },
                    { value: 'entryRequestInvoice', label: 'Request Invoice?' },
                ]
            } else if (this.activeAutomation.event == 'note.created') {
                return [
                    { value: 'userName', label: 'User Name' },
                    { value: 'userEmail', label: 'User Email' },
                    { value: 'clientName', label: 'Client Name' },
                    { value: 'clientUrl', label: 'Client URL' },
                    { value: 'projectName', label: 'Project Name' },
                    { value: 'noteNote', label: 'Note' },
                ]
            } else if (this.activeAutomation.event == 'project.launched' || this.activeAutomation.event == 'project.created') {
                return [
                    { value: 'projectClientName', label: 'Client Name' },
                    { value: 'projectClientUrl', label: 'Client URL' },
                    { value: 'projectName', label: 'Project Name' },
                    { value: 'projectDomains', label: 'Project Domains' },
                    { value: 'projectLaunchDate', label: 'Project Launch Date' },
                    { value: 'services', label: 'Project Services' },
                    { value: 'projectClientContactName', label: 'Client Contact Name' },
                    { value: 'projectClientContactFirstName', label: 'Client Contact First Name' },
                    { value: 'projectClientContactEmail', label: 'Client Contact Email' },
                    { value: 'projectClientContactPhone', label: 'Client Contact Phone' },
                ]
            } else if (this.activeAutomation.event == 'service.started') {
                return [
                    { value: 'projectClientName', label: 'Client Name' },
                    { value: 'projectClientUrl', label: 'Client URL' },
                    { value: 'projectName', label: 'Project Name' },
                    { value: 'projectDomains', label: 'Project Domains' },
                    { value: 'projectLaunchDate', label: 'Project Launch Date' },
                    { value: 'projectClientContactName', label: 'Client Contact Name' },
                    { value: 'projectClientContactFirstName', label: 'Client Contact First Name' },
                    { value: 'projectClientContactEmail', label: 'Client Contact Email' },
                    { value: 'projectClientContactPhone', label: 'Client Contact Phone' },
                    { value: 'serviceName', label: 'Service Name' },
                    { value: 'serviceLevel', label: 'Service Level' },
                ]
            } else if (this.activeAutomation.event == 'service.changed') {
                return [
                    { value: 'projectClientName', label: 'Client Name' },
                    { value: 'projectClientUrl', label: 'Client URL' },
                    { value: 'projectName', label: 'Project Name' },
                    { value: 'projectDomains', label: 'Project Domains' },
                    { value: 'projectLaunchDate', label: 'Project Launch Date' },
                    { value: 'projectClientContactName', label: 'Client Contact Name' },
                    { value: 'projectClientContactFirstName', label: 'Client Contact First Name' },
                    { value: 'projectClientContactEmail', label: 'Client Contact Email' },
                    { value: 'projectClientContactPhone', label: 'Client Contact Phone' },
                    { value: 'fromServiceName', label: 'From Service Name' },
                    { value: 'fromServiceLevel', label: 'From Service Level' },
                    { value: 'toServiceName', label: 'To Service Name' },
                    { value: 'toServiceLevel', label: 'To Service Level' },
                ]
            }

            return [
                { value: 'userName', label: 'User Name' },
                { value: 'userSlackId', label: 'User Slack Id' },

                { value: 'clientName', label: 'Client Name' },
                { value: 'clientUrl', label: 'Client URL' },

                { value: 'workOrderName', label: 'Work Order Name' },
                { value: 'workOrderStatus', label: 'Work Order Status' },
                { value: 'workOrderAmount', label: 'Work Order Amount' },
                { value: 'workOrderPercentage', label: 'Work Order Percentage' },
                { value: 'workOrderUrl', label: 'Work Order Url' },

                { value: 'paymentNumber', label: 'Payment Number' },
                { value: 'paymentPaidAt', label: 'Payment Paid Date' },
                { value: 'paymentDueDate', label: 'Payment Due Date' },
                { value: 'paymentAmount', label: 'Payment Amount' },
                { value: 'paymentIsPaid', label: 'Payment Is Paid?' },
                { value: 'paymentUrl', label: 'Payment Url' },
            ]
        },
        rules() {
            if (this.activeAutomation.event == 'action.created') {
                return [{
                    type: "text",
                    id: "action.notification",
                    label: "Action Title"
                }, {
                    type: "text",
                    id: "action.action",
                    label: "Action Call To Action"
                }, {
                    type: "select",
                    id: "action.user_id",
                    label: "Action Assigned To",
                    choices: this.options.users
                }, {
                    type: "checkbox",
                    id: "action.completed_at",
                    label: "Action Completed",
                },]
            } else if (this.activeAutomation.event == 'note.created') {
                return [
                    { type: "text", id: "note.client.name", label: "Client Name" },
                    { type: "text", id: "note.project.name", label: "Project Name" },
                    {
                        type: "select",
                        id: "note.services.$.name",
                        label: "Services",
                        choices: Object.values(this.services.names).map(name => ({ value: name, label: name }))
                    },
                    { type: "text", id: "note.note", label: "Note" },
                ]
            } else if (this.activeAutomation.event == 'project.launched' || this.activeAutomation.event == 'project.created') {
                return [
                    { type: "text", id: "project.client.name", label: "Client Name" },
                    { type: "text", id: "project.name", label: "Project Name" },
                    { type: "text", id: "project.domains", label: "Project Domains" },
                    { type: "date", id: "project.launch_date", label: "Project Launch Date" },
                    { type: "select", id: "project.services.$.name", label: "Services Name", choices: Object.values(this.services.names).map(name => ({ value: name, label: name })) },
                    { type: "select", id: "project.services.$.level", label: "Services Level", choices: Object.values(this.services.levels).map(level => ({ value: level, label: level })) },
                ]
            } else if (this.activeAutomation.event == 'memo.saved') {
                return [
                    { type: "text", id: "memo.client.name", label: "Client Name" },{
                        type: "select",
                        id: 'memo.is_new',
                        label: 'Memo Is New?',
                        choices: [
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ]
                    },
                    {
                        type: "select",
                        id: 'memo.type',
                        label: 'Memo Type',
                        choices: [
                            { value: 'feedback', label: "Feedback" },
                            { value: 'email', label: "Email" },
                        ]
                    },
                    { type: "text", id: 'memo.updated_by.name', label: 'Memo Updated By (Name)' },
                    { type: "text", id: 'memo.updated_by.email', label: 'Memo Updated By (Email)' },
                    { value: 'memoSubject', id: 'memo.subject', label: 'Memo Subject' },
                    { value: 'memoDescription', id: 'memo.description', label: 'Memo Description' },
                    {
                        type: "select",
                        id: 'memo.status',
                        label: 'Memo Status',
                        choices: [
                            { value: 'new', label: 'New' },
                            { value: 'in-progress', label: 'In Progress' },
                            { value: 'need-more-information', label: 'Need More Information' },
                            { value: 'waiting-on-client', label: 'Waiting on Client' },
                            { value: 'resolved', label: 'Resolved' },
                            { value: 'reopened', label: 'Reopened' },
                        ]
                    }, {
                        type: "select",
                        id: 'memo.updated.status',
                        label: 'Memo Status (Updated)',
                        choices: [
                            { value: 'new', label: 'New' },
                            { value: 'in-progress', label: 'In Progress' },
                            { value: 'need-more-information', label: 'Need More Information' },
                            { value: 'waiting-on-client', label: 'Waiting on Client' },
                            { value: 'resolved', label: 'Resolved' },
                            { value: 'reopened', label: 'Reopened' },
                        ]
                    }, {
                        type: "select",
                        id: 'memo.priority',
                        label: 'Memo Priority',
                        choices: [
                            { value: '1', label: 'None' },
                            { value: '2', label: 'Low' },
                            { value: '3', label: 'Medium' },
                            { value: '4', label: 'High' },
                            { value: '5', label: 'Urgent' },
                        ]
                    }, {
                        type: "select",
                        id: 'memo.updated.priority',
                        label: 'Memo Priority (Updated)',
                        choices: [
                            { value: '1', label: 'None' },
                            { value: '2', label: 'Low' },
                            { value: '3', label: 'Medium' },
                            { value: '4', label: 'High' },
                            { value: '5', label: 'Urgent' },
                        ]
                    }, {
                        type: "select",
                        id: 'memo.assigned_team',
                        label: 'Assigned Team',
                        choices: Object.entries(this.teams).map(([value, label]) => ({ value, label }))
                    }, {
                        type: "select",
                        id: 'memo.updated.assigned_team',
                        label: 'Assigned Team (Updated)',
                        choices: Object.entries(this.teams).map(([value, label]) => ({ value, label }))
                    },
                    {
                        type: "text",
                        id: "memo.assigned_to.name",
                        label: "Assigned User Name"
                    },
                    {
                        type: "text",
                        id: "memo.updated.assigned_to.name",
                        label: "Assigned User Name (Updated)"
                    },
                    {
                        type: "text",
                        id: "memo.assigned_to.email",
                        label: "Assigned User Email"
                    },
                    {
                        type: "text",
                        id: "memo.updated.assigned_to.email",
                        label: "Assigned User Email (Updated)"
                    },
                ]
            } else if (this.activeAutomation.event == 'entry.saved') {
                return [
                    { type: "text", id: "entry.user.name", label: "User Name" },
                    { type: "text", id: "entry.client.name", label: "Client Name" },
                    { type: "text", id: "entry.week", label: "Week" },
                    { type: "numeric", id: "entry.planned", label: "Planned Hours" },
                    { type: "numeric", id: "entry.actual", label: "Actual Hours" },
                    { type: "numeric", id: "entry.billableNow", label: "Billable Now" },
                    { type: "numeric", id: "entry.billableGoal", label: "Billable Goal" },
                    { type: "checkbox", id: "entry.request_sprint", label: "Request Sprint?" },
                    { type: "checkbox", id: "entry.request_invoice", label: "Request Invoice?" },
                ]
            }

            return [{
                type: "text",
                id: "client.name",
                label: "Client Name"
            }, {
                type: "numeric",
                id: "payment.amount",
                label: "Payment Amount"
            }, {
                type: "text",
                id: "payment.due_date",
                label: "Payment Due Date",
                operators: ['is', 'is before', 'is after']
            }, {
                type: "checkbox",
                id: "payment.is_paid",
                label: "Payment Is Paid?"
            }, {
                type: "text",
                id: "sprint.name",
                label: "Work Order Name"
            }, {
                type: "select",
                id: "sprint.status",
                label: "Work Order Status",
                choices: {
                    active: "Active",
                    finished: "Finished",
                    archived: "Archived",
                }
            }, {
                type: "numeric",
                id: "sprint.maximum",
                label: "Work Order Amount"
            }, {
                type: "numeric",
                id: "sprint.percentage",
                label: "Work Order Percentage"
            }];
        }
    },
    methods: {
        async loadTrelloBoards() {
            const { data } = await axios.get(this.$route('trello.boards'))
            this.trello.boards = data
        },
        async loadTrelloLists(boardId) {
            this.trello.lists = []
            const { data } = await axios.get(this.$route('trello.lists', boardId))
            this.trello.lists = data
        },
        async loadTrelloLabels(boardId) {
            this.trello.labels = []
            const { data } = await axios.get(this.$route('trello.labels', boardId))
            this.trello.labels = data
        },
        money (value) {
            if (value) {
                return '$' + Number(value).toLocaleString(undefined, {minimumFractionDigits: 2})
            }

            return '--'
        },
        clientName(id) {
            return get(this.clients, id, '--');
        },
        numberOfNewLinesIn(str) {
            if (str) {
                let matches = str.match(/\n/g)
                if (matches) {
                    return Math.min(matches.length + 1, 5)
                }
            }
            return 1
        },
        async testActiveAction() {
            this.testing = true
            this.testAgainst.object = null;
            this.testAgainst.showing = 'matches';
            this.testAgainst.running = false;
            this.testAgainst.loading = true;
            switch (this.activeAutomation.event) {
                case 'schedule.monthly':
                case 'schedule.weekly':
                case 'schedule.daily':
                    switch (this.activeAutomation.scope) {
                        case 'payments':
                            this.testAgainst.label = 'Payment'
                            break;
                        case 'sprints':
                            this.testAgainst.label = 'Work Order'
                            break;
                    }
                    break;
                case 'project.launched':
                case 'project.created':
                    this.testAgainst.label = 'Project'
                    break;
                case 'service.started':
                case 'service.changed':
                    this.testAgainst.label = 'Service'
                    break;
                case 'memo.saved':
                    this.testAgainst.label = 'Feedback'
                    break;
                case 'entry.saved':
                    this.testAgainst.label = 'Time Entry'
                    break;
                case 'action.created':
                    this.testAgainst.label = 'Action'
                    break;
                case 'payment.received':
                case 'payment.paid':
                    this.testAgainst.label = 'Payment'
                    break;
                case 'sprint.created':
                case 'sprint.updated':
                    this.testAgainst.label = 'Work Order'
                    break;
                case 'note.created':
                    this.testAgainst.label = 'Note'
                    break;
            }

            const query = {
                type: null,
                event: this.activeAutomation.event,
                query: this.activeAutomation.query
            }

            switch(this.testAgainst.label) {
                case 'Feedback':
                    query.type = 'memo'
                    break;
                case 'Time Entry':
                    query.type = 'entry'
                    break;
                case 'Project':
                    query.type = 'project'
                    break;
                case 'Payment':
                    query.type = 'payment'
                    break;
                case 'Work Order':
                    query.type = 'sprint'
                    break;
                case 'Action':
                    query.type = 'action'
                    break;
                case 'Note':
                    query.type = 'note'
                    break;
                case 'Service':
                    query.type = 'service'
                    break;
            }

            try {
                const { data } = await axios.post(this.$route("automations.load"), query)
                this.testAgainst.objects = data;
            } catch(err) {
                console.error(err);
                alert("Loading test objects failed. Double check your filters and try again.")
            }
            this.testAgainst.loading = false;
        },
        async runTest() {
            const query = {
                type: null,
                event: this.activeAutomation.event,
                object: this.testAgainst.object,
                query: this.activeAutomation.query,
                actions: this.activeAutomation.actions
            }

            switch(this.testAgainst.label) {
                case 'Feedback':
                    query.type = 'memo'
                    break;
                case 'Project':
                    query.type = 'project'
                    break;
                case 'Time Entry':
                    query.type = 'entry'
                    break;
                case 'Payment':
                    query.type = 'payment'
                    break;
                case 'Work Order':
                    query.type = 'sprint'
                    break;
                case 'Action':
                    query.type = 'action'
                    break;
                case 'Note':
                    query.type = 'note'
                    break;
                case 'Service':
                    query.type = 'service'
                    break;
            }

            await axios.post(this.$route("automations.run"), query)
            alert("The automation has been triggered!");
            this.testing = false
        },
        removeActiveAction() {
            this.activeAutomation.actions.splice(this.activeActionIndex, 1)
            this.activeActionIndex = 0
        },
        addAction() {
            if (this.newAction) {
                const action = {
                    type: this.newAction
                }
                switch(this.newAction) {
                    case 'slack':
                        action.channel = ''
                        action.title = ''
                        action.message = ''
                        break;
                    case 'trello':
                        action.board = ''
                        action.list = ''
                        action.labels = []
                        action.name = ''
                        action.description = ''
                        break;
                    case 'action':
                        action.user_id = null
                        action.notification = ''
                        action.action = ''
                        break;
                }
                this.activeActionIndex = this.activeAutomation.actions.length
                this.activeAutomation.actions.push(action)
                this.newAction = ''
            }
        },
        removeDayOfMonth(idx) {
            this.activeAutomation.event_data.days.splice(idx, 1)
        },
        addDayOfMonth() {
            if (this.dayOfMonthToAdd) {
                this.activeAutomation.event_data.days.push(this.dayOfMonthToAdd)
                this.dayOfMonthToAdd = null
            }
        },
        deactivate() {
            this.activeAutomation = null
        },
        activate(automation) {
            this.activeAutomation = cloneDeep(automation)
        },
        createAutomation() {
            this.activeAutomation = cloneDeep(this.newAutomation)
        },
        async remove() {
            if (confirm("Are you sure you want to delete this automation?")) {
                this.deleting = true
                await axios.delete(this.$route("automations.destroy", this.activeAutomation.id))
                this.activeAutomation = null
                this.deleting = false
            }
        },
        async save() {
            this.saving = true
            const automation = cloneDeep(this.activeAutomation)
            automation.query = this.$refs['queryBuilder'].query;
            try {
                if (automation.id) {
                    automation._method = "PUT"
                    await axios.post(
                        this.$route("automations.update", automation.id),
                        automation
                    )
                } else {
                    await axios.post(this.$route("automations.store"), automation)
                }
                this.activeAutomation = null
                this.$inertia.reload()
            } catch (err) {
                console.error(err)
            }
            this.saving = false
        }
    },
    watch: {
        'activeAction.type'() {
            if (this.activeAction &&this.activeAction.type == 'trello') {
                this.loadTrelloBoards()
                if (this.activeAction.board) {
                    this.loadTrelloLists(this.activeAction.board)
                    this.loadTrelloLabels(this.activeAction.board)
                }
            }
        },
        'activeAction.board'() {
            if (this.activeAction && this.activeAction.board) {
                this.loadTrelloLists(this.activeAction.board)
                this.loadTrelloLabels(this.activeAction.board)
            }
        },
        'activeAutomation'() {
            if (this.activeAutomation) {
                nextTick(() => {
                    this.$refs['queryBuilder'].query = this.activeAutomation.query || {
                        "logicalOperator": "all",
                        "children": []
                    };
                });
            }
        },
        'activeAutomation.event'(from, to) {
            if (this.activeAutomation && from && to) {
                switch (this.activeAutomation.event) {
                    case 'schedule.monthly':
                        this.activeAutomation.event_data = {
                            days: [],
                            hour: 8,
                            minute: '00'
                        }
                        break;
                    case 'schedule.weekly':
                        this.activeAutomation.event_data = {
                            days: [],
                            hour: 8,
                            minute: '00'
                        }
                        break;
                    case 'schedule.daily':
                        this.activeAutomation.event_data = {
                            hour: 8,
                            minute: '00'
                        };
                        break;
                    default:
                        this.activeAutomation.event_data = null;
                }
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
